import React from 'react'
import Menu from '../Menu';
import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    
    <div className="App">
        <Menu />
        <Outlet />
    </div>
  )
}

export default Home