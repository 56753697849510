const winkNLP = require( 'wink-nlp' );
const model = require( 'wink-eng-lite-web-model' );
const nlp = winkNLP( model )

const its = nlp.its;
const as = nlp.as;

const wikipipeline = (query) => {
    return new Promise((resolve)=>{
        var wikidata = {
            query:query
        };


        var url = "https://en.wikipedia.org/w/api.php"; 
    
            var params = {
                action:"query",
                titles: query,
                format: "json",
                explaintext:1,
                prop:"extracts",
            };
    
            url = url + "?origin=*";
            Object.keys(params).forEach(function(key){url += "&" + key + "=" + params[key];});

        fetch(url).then(function(response){return response.json();})
        .then(async function(response) {
    
            if(response.query.pages[Object.keys(response.query.pages)[0]].redirect==="") console.log(true)

            let page = response.query.pages[Object.keys(response.query.pages)[0]]
            wikidata.extract = response.query.pages[Object.keys(response.query.pages)[0]].extract;
            wikidata.pageid = page.pageid;
            wikidata.title = page.title;

            if(wikidata.extract==="") {wikidata.disambiguation = true}else{wikidata.disambiguation = false}
            console.log(wikidata)

            var data = wikidata.extract
            var cleaned_data = data.replace(/(\r\n|\n|\r)/gm, "")

            var chapters2 = cleaned_data.split("==")
            var index = []
            var chapters = []

            for (let c of chapters2){
                if(c.length!=0){
                    chapters.push(c)
                }
            }

            for(let c=0;c<chapters.length;c++){
                let chapter = chapters[c]
                let type = '';
                if(chapters[c+1]){
                    if(chapter.length>chapters[c+1].length){
                        if(chapters[c+1].length!=0 || chapters[c+1][0]=="="){
                            type = "chapter";
                        }
                    }else if(chapter.length<chapters[c+1].length){
                        if(chapters[c+1].length!=0 || chapters[c+1][0]=="="){
                            if(chapter[0]=="="){
                                type="subtitle"
                            }else type = "title"
                        }
                    } 
                    
                }
                if(chapter.length!=0){
                    if(chapter[0]!='='){
                        index.push({length:chapter.length,text:chapter, type:type})
                    }else{
                        index.push({length:chapter.length,text:chapter.slice(1), type:type})
                    }
                    
                }
                
            }

            for (let i of index){
                if(i.type=="title" || i.type=="subtitle") {
                    let array = i.text.split(" ")
                    let array2 = [];
                    for (let a of array){
                        if(a!=""){
                            array2.push(a);
                        }
                    }
                    i.text = array2.join(' ')
                }
            }

            wikidata.extract = index;

            resolve(wikidata)

            // const doc = await nlp.readDoc( to_parse );

            // console.log(doc.tokens().out( its.POS, as.freqTable ))

            // var url2 = "https://en.wikipedia.org/w/api.php"; 
    
            // var params2 = {
            //     action:"query",
            //     titles: query,
            //     format: "json",
            //     generator:"categories",
            //     cllimit:500,
            //     prop:"info",
            // };
    
            // url2 = url2 + "?origin=*";
            // Object.keys(params2).forEach(function(key){url2 += "&" + key + "=" + params2[key];});

            // fetch(url2).then(function(response){return response.json();}).then(async function(response) {
            //     wikidata.categories = [];
            //     for (let e of Object.keys(response.query.pages)){
            //         let cat = response.query.pages[e];
            //         wikidata.categories.push({pageid:cat.pageid,title:cat.title.slice(9,cat.title.length)})
                    
            //     }
            //     console.log(response)
            // })
        })
    })

}

export default wikipipeline