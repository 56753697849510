import React from 'react'
import './styles/App.css';
import './styles/Menu.css';
import { Link } from "react-router-dom";

const Menu = () => {
  return (
    <div id="menu">
        <nav>
          <p><Link to="/" className=".menu" style={{color:({isActive, isPending})=>{
            return {
              color: isActive ? "yellow" : "green"
            }
          }}}>search</Link></p>
          <p style={{color:"white"}}><Link to="/cloud" className=".menu" 
          
            // style={{color:({isActive, isPending})=>{
            //   return {
            //     color: isActive ? "orange" : "white"
            //   }
            // }}}
          >cloud</Link></p>
        </nav>
        <p id="title">WikiClouds</p>
    </div>
  )
}

export default Menu