import './styles/App.css';
import Search from './pages/Search';
import Cloud from './pages/Cloud';
import Home from './pages/Home';
import NoPage from './pages/NoPage';
import { useState,useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {
  const [data,setData] = useState({})
  const [menu, setMenu] = useState("/")
  
  function change(command, data){
    if(command == "cloud"){
      // document.getElementById("searchContainer").style.display= "none";
      // document.getElementById('cloudContainer').style.display="grid";
      // document.getElementById('menu').style.height="10vh"

      setData(data)
    }

    setData(data)
  }



  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}>
          <Route index element={<Search change={change} />} />
          <Route path = "cloud" element={ <Cloud change={change} data={data}/> } />
          {/* <Route path = "427" element={ <Disambiguation change={change}/> } /> */}
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
