import React from 'react';
import '../styles/App.css';
import '../styles/Search.css'
import { useState,useEffect } from "react";
import wikipipeline from '../wikipipeline';
import { Link } from "react-router-dom";

const Search = ({change}) => {

    const [value,setValue] = useState("none")
    const [results,setResults] = useState([]);

    async function test(string){

        var url = await createEndpoint(string, "text")
        // console.log(url);
        fetch(url).then(function(response){return response.json();})
        .then(async function(response) {
    
            // console.log(response);
            // var res = JSON.stringify(response).toLowerCase();
            updateResults(response[1])
            // console.log(res);
        })
    }
    
    function createEndpoint(name, props){
        return new Promise(function(resolve, reject){
            var url = "https://en.wikipedia.org/w/api.php"; 
    
            var params = {
                action:"opensearch",
                search: name,
                limit:5,
                format: "json",
                // page:name,
                prop:props,
            };
    
            url = url + "?origin=*";
            Object.keys(params).forEach(function(key){url += "&" + key + "=" + params[key];});
            resolve(url);
        })
        
    }

    function handleChange(event){
        if(event.target.value.length>=3){
            test(event.target.value);
        }else{
            setValue("none");
        }
    }

    function updateResults(list){
        // console.log(list)
        if(list.length>0){
            setValue("grid");
            setResults(list)
        }
        
    }

    async function handleClick(event){
        let string = event.target.innerText;
        
        let str='';
        
        if(string[0]!="<"){
            console.log("bye")
            for (let l of string){
                if(l==" ") str +="%20"
                else str+=l
            }
        }
        
        var data = await wikipipeline(str)
        
        change("cloud", data)
       
    }

    return (
    <div id="searchContainer" style={{display:"grid"}}>
            <input id="search" type="text" placeholder="search Wikipedia" onChange={handleChange} />
            <div id="results" style={{display:value, "place-items":"center"}}>
                {results.map((object, i) =><Link className='result' onClick={handleClick} sx={{"width":"100%","place-items":"center","display":"grid"}} to="/cloud"> <p key={i} >{object}</p></Link>)}
            </div>
        </div>
    );
};

export default Search;