import React from 'react'
import ReactWordcloud from 'react-wordcloud';
import '../styles/App.css';
import '../styles/Cloud.css';
import { useState,useEffect } from "react";
const winkNLP = require( 'wink-nlp' );
const model = require( 'wink-eng-lite-web-model' );
const nlp = winkNLP( model )

const its = nlp.its;
const as = nlp.as;

const Cloud = ({change, data}) => {

  const [words, setWords] = useState([]);

  useEffect(() => {
    if(data.extract){
        let string = '';
        for(let c of data.extract){
            string +=' ' + c.text;
        }

        processData(string);
    }
  }, [data])

  // useEffect(()=>{

  // }, [words])
  
  async function processData(string){
    var stats = {}

    const doc = nlp.readDoc( string );
    stats.total = doc.tokens().out(its.type).length;

    let text = doc.tokens().filter( (t)=>(t.out(its.type)!=="punctuation") ).out();
    stats.punctuation = stats.total - text.length
    text = text.join(" ")
    // text = text.toLowerCase()
    const doc2 = nlp.readDoc(text);
    let array = doc2.tokens().out(its.type,as.freqTable)
    stats.data = []
    for (let a of array){
      stats.data.push({
        type:a[0],
        value:a[1]
      })
    }
    // console.log(stats)
    let array2 = doc2.tokens().out(its.value,as.freqTable)
    // console.log(array2)
    let useful_tokens = [];
    for (let a of array2){
      if(! await commonwordcheck(a[0])){
        if(useful_tokens.length<=100 && a[1]>1){
          useful_tokens.push({word:a[0],value:a[1]})
        }
        
      }
    }
    let tokens = []
    for (let u of useful_tokens){
      let duplicate = false;
      for(let u2 of useful_tokens){
        if(u.word+"s"==u2.word){
          duplicate = true;
          tokens.push({
            text:u.word,value:u.value+u2.value
          })
        }
        if(u.word[u.word.length-1]=="s" && u.word == u2.word+"s"){
          duplicate = true;
        }
      }
      if(!duplicate){
        tokens.push({
          text:u.word,value:u.value
        })
      }
    }

    setWords(tokens)
  }

  function commonwordcheck(word){
    return new Promise((resolve)=>{
      word = word.toLowerCase();
      let commonwords = [
        //DET
        "the","a",'an','my','your','his','her','its','our','their','this','which',
        
        //ADP
        ,"in","to",'during'

        //CONJ
        ,"and","or",'but','that','if','when','since','while'

        //PRON
        ,'i','you','he','she','it','we','they','who','what'
        
        //AUX
        ,'had',"is",'has','are','have','was','were','got','do','does','did'
        
        ,'by','from','for','on','which','at','been','also','or','than','after',"with","'s",'of','be','him','her','them',"'re",'so','into','as','not','no','never','nothing','nobody',"n't"
      ]
      for (let c of commonwords){
        if(word==c) resolve(true)
      }
      resolve(false)
    })

    
  }



  const callbacks={
    getWordColor:(word)=>{
      let col = "blue"
      if(word.value>5) col = "green"
      if(word.value > 10) col = "red"
      if(word.value > 25) col = "gold"

      return col
    },
    onWordClick:console.log,
    onWordMouseOver:console.log,
    getWordTooltip:word=>`${word.value}`,
  }
  const options={
    rotations:0,
    fontSizes:[12,50]
  };
  const size=[600,400];


  return (
    <div id="cloudContainer">
      
      <ReactWordcloud words={words} callbacks={callbacks} options={options} />
    </div>
  )
}

export default Cloud